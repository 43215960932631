import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledPageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function FourOhFourPage() {
  return (
    <StyledPageContainer>
      <h1>404</h1>
      <p>Page not found</p>
      <p>
        <Link to="/">return home?</Link>
      </p>
    </StyledPageContainer>
  );
}
